export default [
  {
    value: 'regular',
    label: 'Regular',
    description:
      "Unleash the Power of CampaignHQ Managed Email Servers - No Setup Needed! Focus on Business Growth, We'll Handle the Rest!",
  },
  {
    value: 'connect',
    label: 'Connect',
    description:
      'Connect plan allows you to connect your own Amazon SES account or any other email service provider and send unlimited emails.',
  },
]

