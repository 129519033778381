import { Fragment, useState } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { CheckIcon, CheckBadgeIcon } from '@heroicons/react/24/outline'
import { useRouter } from 'next/router'
export default function Example({ open, setOpen }) {
  const router = useRouter()
  const handleCtaClick = () => {
    Beacon('open')
    // openTalkToUsModal()
    // router.push({
    //   pathname: '/signup',
    //   query: { code: 'GET50' },
    // })
  }

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={setOpen}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative w-[600px]  transform overflow-hidden rounded-lg bg-indigo-800 px-4 pb-4 pt-5 text-left text-white shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-sm sm:p-6">
                <div>
                  <div className="mt-3 sm:mt-5">
                    <p className="text-sm">Limited offer</p>
                    <Dialog.Title
                      as="h3"
                      className="text-[30px] font-semibold leading-6"
                    >
                      Exclusive discount
                    </Dialog.Title>
                    <div className="my-10">

                      <div className='font-bold mb-5'> Tell us about your requirements and get exclusive discount on your plan.</div>
                      <p className="text-sm opacity-[0.8]">
                        Whatever your marketing goal may be, we're here to help
                        you succeed.
                      </p>
                      {/* <div className="mt-5 flex items-center space-x-3">
                        <span>Use code:</span>{' '}
                        <span className="mt-1 inline-block rounded bg-green-700 py-2 px-5 font-semibold opacity-[0.9]">
                          GET50
                        </span>
                      </div> */}
                    </div>
                  </div>
                </div>
                <div className="mt-5 sm:mt-6">
                  <button
                    type="button"
                    className="inline-flex w-full items-center justify-center rounded-md border border-transparent bg-white px-4 py-2 text-base font-semibold text-indigo-900 opacity-[0.8] shadow-sm hover:bg-indigo-50 sm:text-sm"
                    onClick={() => handleCtaClick()}
                  >
                    Talk to us
                  </button>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  )
}
