import UnlimitedSubscriberEnquiryModal from '@/components/modals/UnlimitedSubscriberEnquiryModal'
import { useState } from 'react'
export default function HoldOnCta() {
  const [openUnlimitedSubscriberEnquiryModal, setOpenUnlimitedSubscriberEnquiryModal] = useState(false)

  return (
    <div className="bg-gray-800">
      <UnlimitedSubscriberEnquiryModal open={openUnlimitedSubscriberEnquiryModal} setOpen={setOpenUnlimitedSubscriberEnquiryModal} />
      <div className="mx-auto max-w-7xl px-6 py-16 sm:py-24 lg:flex lg:justify-between lg:px-8">
        <div className="max-w-xl">
          <h2 className="text-4xl font-bold tracking-tight text-white sm:text-5xl lg:text-6xl">Get best deal!</h2>
          <p className="mt-5 text-xl text-gray-200">
            Looking for unlimited subscribers plan or 1 time payment plan? We got you covered!
          </p>
        </div>
        <div className="mt-10 w-full max-w-xs">
          <button type="button"
            onClick={() => setOpenUnlimitedSubscriberEnquiryModal(true)}
            className='w-full flex items-center justify-center px-8 py-3 border border-transparent text-base font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 md:py-4 md:text-lg md:px-10'>
            Get in touch today
          </button>
        </div>
      </div>
    </div>
  )
}
