/*
  This example requires some changes to your config:

  ```
  // tailwind.config.js
  module.exports = {
    // ...
    plugins: [
      // ...
      require('@tailwindcss/forms'),
    ],
  }
  ```
*/
import { useState } from 'react'
import { useRouter } from 'next/router'
import api from '@/api'
import { CatchPromise } from '@/utils/catch-promise'
export default function Example() {
  const [email, setEmail] = useState('')
  const [isEmailValid, setEmailValid] = useState(false)
  const [addingSubscriber, setAddingSubscriber] = useState(false)
  const router = useRouter()
  const onEmailFieldChange = (e) => {
    setEmail(e.target.value)
    const isEmailValid = e.target.value.match(
      /^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i
    )
    setEmailValid(isEmailValid)
  }

  const addSubscriber = async () => {
    if (addingSubscriber) {
      return
    }
    if (email.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i)) {
      setAddingSubscriber(true)
      const [err, res] = await CatchPromise(
        api.subscribers.add({
          email,
          custom_fields: { source: 'pricing-cta' },
        })
      )
      setAddingSubscriber(false)
    }

    openChat()
  }

  const openChat = function () {
    if (Beacon) {
      Beacon('open')
    }
  }

  return (
    <div className="mb-16 bg-white">
      <div className="mx-auto">
        <div className=" text-2xl sm:text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl lg:col-span-7">
          <h2 className="text-center">
            Your satisfaction is our priority! <br />
            Let's tailor a pricing plan to perfectly suit your needs.
          </h2>{' '}
          {/* <p className="inline sm:block lg:inline xl:block">Talk to us</p> */}
        </div>
        <div className="mt-10 flex w-full justify-center">
          <div className="flex gap-x-4">
            <input
              type="text"
              onChange={(e) => onEmailFieldChange(e)}
              placeholder="Email"
              className="w-full rounded-md border-gray-300 bg-white px-3.5 py-2.5 text-sm font-semibold  text-gray-600 md:min-w-[300px]"
            />
            <button
              onClick={(e) => addSubscriber(e)}
              type="submit"
              className="flex-none rounded-md bg-indigo-600 px-3.5 py-2.5 text-sm font-semibold text-white hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
            >
              Chat with us
            </button>
          </div>
          {/* <p className="mt-4 text-sm leading-6 text-gray-900">
            We care about your data. Read our{' '}
            <a href="#" className="font-semibold text-indigo-600 hover:text-indigo-500">
              privacy&nbsp;policy
            </a>
            .
          </p> */}
        </div>
      </div>
    </div>
  )
}
