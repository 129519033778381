import mailchimp from '@/images/logos/mailchimp.svg'
import emailOctopus from '@/images/logos/emailoctopus.svg'
import aweber from '@/images/logos/aweber.svg'
import campaignMonitor from '@/images/logos/campaignmonitor.svg'
import constantcontact from '@/images/logos/constantcontact.svg'
import mailerlite from '@/images/logos/mailerlite.svg'
import mailmodo from '@/images/logos/mailmodo.svg'
import Image from 'next/image'
import { useEffect, useState } from 'react'
const competitors = [
  {
    name: 'Mailchimp',
    logo: mailchimp,
    savings: '500%',
    alt: 'Mailchimp vs CampaignHQ',
  },
  {
    name: 'Email Octopus',
    logo: emailOctopus,
    savings: '10%',
    alt: 'Email Octopus vs CampaignHQ',
  },
  {
    name: 'Aweber',
    logo: aweber,
    savings: '10x',
    classes: 'w-10 h-19',
    alt: 'Aweber vs CampaignHQ',
  },
  {
    name: 'Campaign Monitor',
    logo: campaignMonitor,
    savings: '10x',
    alt: 'Campaign Monitor vs CampaignHQ',
  },
  {
    name: 'Constant Contact',
    logo: constantcontact,
    savings: '10x',
    alt: 'Constant Contact vs CampaignHQ',
  },
  {
    name: 'Mailerlite',
    logo: mailerlite,
    savings: '10x',
    alt: 'Mailerlite vs CampaignHQ',
  },
  {
    name: 'Mailmodo',
    logo: mailmodo,
    savings: '10x',
    alt: 'Mailmodo vs CampaignHQ',
  },
]

export default function () {
  const [currentIndex, setCurrentIndex] = useState(0)

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIndex((currentIndex) => {
        if (currentIndex == competitors.length - 1) {
          return 0
        } else {
          return currentIndex + 1
        }
      })
    }, 1000)
    return () => clearInterval(interval)
  }, [])

  return (
    <div className="min-h-[70px]">
      {competitors.map((competitor, index) => {
        return (
          <div
            key={index}
            className={`flex items-center ${
              currentIndex == index ? 'visible' : 'hidden'
            }`}
          >
            <p className="w-[170px] font-semibold text-gray-700"> Compare price with </p>{' '}
            <Image
              className="h-[100px] max-w-[170px]"
              src={competitor.logo}
              alt={competitor.alt}
            ></Image>
          </div>
        )
      })}
    </div>
  )
}
